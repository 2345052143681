.driver-popover.custom-theme {
  background-color: var(--mantine-color-gray-0);
  color: #000;
}

.driver-popover.new-preset {
  background-color: var(--mantine-color-gray-0);
  color: #000;
  max-width: 1200px;
}

.driver-popover.custom-theme .driver-popover-title {
  font-size: 20px;
}

.driver-popover.custom-theme .driver-popover-title,
.driver-popover.custom-theme .driver-popover-description,
.driver-popover.custom-theme .driver-popover-progress-text {
  color: #000;
}

.driver-popover-footer button .driver-popover-footer.custom-theme button,
.driver-popover.custom-theme button {
  flex: 1;
  text-align: center;
  background-color: var(--mantine-color-teal-4);
  border: 1px solid #000;
  text-shadow: none;
  border-radius: 5px;
  font-size: var(--mantine-font-size-md);
  color: var(--mantine-color-dark-9);

  &:disabled,
  &[data-disabled] {
    background-color: var(--mantine-color-gray-2);
    color: var(--mantine-color-gray-6) !important;
    border: 1px solid var(--mantine-color-gray-6);
  }
}

.driver-popover.custom-theme button:focus {
  background-color: var(--mantine-color-teal-4);
}

.driver-popover.custom-theme button:hover {
  background-color: var(--mantine-color-teal-3);
  color: var(--mantine-color-dark-9);
}

.driver-popover.custom-theme .driver-popover-navigation-btns {
  justify-content: space-between;
  gap: 3px;
}

.driver-popover.custom-theme .driver-popover-close-btn {
  padding: 1px;
  background-color: var(--mantine-color-gray-0);
  border: none;
  color: #9b9b9b;
}

.driver-popover.custom-theme .driver-popover-close-btn:hover {
  color: #000;
  background-color: var(--mantine-color-gray-0);
}

.driver-popover.custom-theme .driver-popover-close-btn:focus {
  color: #000;
  background-color: var(--mantine-color-gray-0);
}

.driver-popover.custom-theme .driver-popover-arrow-side-left.driver-popover-arrow {
  border-left-color: var(--mantine-color-gray-0);
}

.driver-popover.custom-theme .driver-popover-arrow-side-right.driver-popover-arrow {
  border-right-color: var(--mantine-color-gray-0);
}

.driver-popover.custom-theme .driver-popover-arrow-side-top.driver-popover-arrow {
  border-top-color: var(--mantine-color-gray-0);
}

.driver-popover.custom-theme .driver-popover-arrow-side-bottom.driver-popover-arrow {
  border-bottom-color: var(--mantine-color-gray-0);
}
