.panelResize {
  width: 4px;
  margin: 0 -2px;
  z-index: 190;
  border-radius: 4px 0 0 0;
}

.panelResize[data-resize-handle-state='hover'],
.panelResize[data-resize-handle-active] {
  background-color: var(--mantine-color-teal-4);
}
