.assetsTable {
  margin-left: 12px;
  margin-right: 12px;
  outline: none;
  padding-block: 5px;
}

.assetsTableRow {
  background-color: var(--mantine-color-white);
  margin-bottom: 6px;
  outline: none;
  border: 1px solid transparent;
  border-left: 6px solid transparent;
}

.assetsTableHeaderRow {
  margin-bottom: 6px;
  outline: none;
  border: 1px solid transparent;
  border-left: 6px solid transparent;
}

.assetsTableRowExpanded {
  box-sizing: border-box;
  background-color: var(--mantine-color-white);
  margin-top: -6px;
  border-radius: 4px;
  margin-bottom: 6px;
  outline: none;
  border: 1px solid transparent;
  border-left: 6px solid transparent;
}

.assetsTableRowExpandedInner {
  padding-block: 4px;
  border: 1px solid transparent;
  border-left: 6px solid transparent;
}

.assetsTableSelectedRow {
  border: 1px solid var(--mantine-color-teal-4);
  border-radius: 5px;
  border-left: 6px solid var(--mantine-color-teal-4);
  box-shadow: 0px 4px 10px 0px #38d9a94d;
  z-index: 1;
}

.assetsTableSelectedExpandedRow {
  border: 1px solid var(--mantine-color-teal-4);
  border-radius: 5px;
  border-left: 6px solid var(--mantine-color-teal-4);
  box-shadow: 0px 0px 10px 0px #38d9a94d;
  padding-left: 0px;
}

.assetHoverRow {
}

.assetHoverRow:hover {
  border: 1px solid var(--mantine-color-teal-2);
  border-radius: 5px;
  border-left: 6px solid var(--mantine-color-teal-2);
  box-shadow: 0px 0px 10px 0px #38d9a94d;
}
